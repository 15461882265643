@import "~assets/styles/abstracts/variables.scss";

.ant-form-item-has-error {
	input[type="text"],
	input[type="number"] {
		background: #fde9e6 !important;
		border: 1px solid #f3b0a5 !important;
		box-shadow: 0 0 0 2px rgb(243 176 165 / 20%) !important;
	}
}

.ant-form-item-explain-error {
	display: flex;
	align-items: center;
	font-size: 17px;
	margin-top: 2px;

	div {
		color: #c07367;
	}

	&:before {
		content: "";
		display: block;
		width: 13px;
		height: 13px;
		background: $red;
		border-radius: 50%;
		margin-right: 8px;
		flex: 0 0 auto;
	}
}

.input {
	position: relative;

	&.is-success {
		input[type="text"],
		input[type="number"] {
			background: #e5f9cf;
			border-color: #b0d884;
		}

		p {
			margin-top: 2px;
			font-size: 14px;
			color: #6b8b48;
			margin-top: 5px;
			display: flex;
			align-items: center;

			&:before {
				content: "";
				display: block;
				width: 13px;
				height: 13px;
				background: #b0d884;
				border-radius: 50%;
				margin-right: 8px;
				flex: 0 0 auto;
			}
		}
	}

	&.is-error {
		input[type="text"],
		input[type="number"],
		input[type="password"] {
			background: #fde9e6 !important;
			border-color: #f3afa5 !important;
		}
	}

	&.is-search {
		input[type="text"],
		input[type="number"] {
			padding-right: 55px;
			border-color: #b6bccd !important;
			background: #fff;
		}
	}

	&.is-footer-search {
		button {
			background: #e6e9ed !important;

			//&:hover,
			//&:focus{
			//  background: #dbe0e7;
			//}
		}

		svg {
			fill: $primary;
		}
	}

	small {
		font-size: 16px;
		color: #717c8c;
		margin-top: 2px;
		display: block;
	}

	.input-label {
		padding: 0 5px;
		background: #fff;
		color: #000;
		font-size: 14px;
		font-weight: 400;
	}

	.input-label[data-position="relative"] {
		position: relative;
	}

	.input-label[data-position="absolute"] {
		position: absolute;
		top: -12px;
		left: 8px;
		z-index: 2;
	}

	input[type="text"],
	input[type="number"] {
		border-radius: 8px !important;
		-moz-appearance: textfield;
		z-index: 1;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:active,
		&:focus {
			background: #fff !important;
		}

		&.ant-input-disabled {
			background-color: #f5f5f5 !important;
			border-color: #b6bccd;
		}
	}

	.anticon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ant-input-group-addon {
		position: absolute !important;
		top: 0;
		bottom: 0;
		right: 4px !important;
		left: unset !important;
		margin: auto;
		display: block;
		width: 30px;
		height: 30px;
	}

	button {
		width: 30px !important;
		height: 30px !important;
		border: none;
		background: #ededef !important;
		border-radius: 8px !important;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		//&:focus,
		//.active {
		//  background: $primary;
		//}

		svg {
			fill: #282f38;
			width: 20px;
			height: 20px;
		}

		//&:hover {
		//  background: #4772df;
		//}
	}
}
