@import "~assets/styles/abstracts/variables.scss";

.label {
	&.light {
	}

	&.dark {
		color: $white;
	}
}
.download {
	&.light {
	}

	&.dark {
		& path {
			fill: $primary;
		}
	}
}
