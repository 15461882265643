@use "assets/styles/abstracts/mixins/_flex" as flex;
@import "~assets/styles/abstracts/variables.scss";

// large
$lgWidth: 47px;
$lgHeight: 39px;
// small
$smWidth: 40px;
$smHeight: 30px;

.pagination {
	display: flex;

	&__left,
	&__right {
		min-height: $lgHeight !important;
		padding-block: 8px !important;
		display: flex;
		align-items: center;
		gap: 8px;

		&[disabled] {
			color: $RollingStone !important;
			svg path {
				fill: $RollingStone !important;
			}
		}
		color: $primary !important;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		svg path {
			fill: $primary !important;
		}
		span {
			line-height: 0;
		}
	}
	&__right {
		svg {
			transform: rotate(-180deg);
		}
	}
}

:global {
	.ant-pagination {
		gap: 10px;
	}

	.ant-pagination-item {
		@include flex.flex-both-center;

		flex: 0 0 $lgWidth !important;
		min-width: $lgWidth;
		min-height: $lgHeight;

		margin: 0 !important;
		background: #fff;
		font-size: 17px;
		line-height: 129%;
		color: $RollingStone;
		border-radius: 8px;
		border: 1px solid #e6e6e6 !important;

		a {
			color: $primary !important;
			font-weight: 700;
		}

		&-active {
			border-radius: 8px;
			border: 1px solid #fff !important;
			background: #123579 !important;
			box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
			a {
				color: #fff !important;
			}
		}
	}

	.ant-pagination-prev {
		margin: 0 !important;
	}
	.ant-pagination-jump-prev,
	.ant-pagination-jump-next {
		@include flex.flex-both-center;
		flex-direction: column;
		height: auto !important;
	}
}

:global {
	.ant-pagination-mini {
		.ant-pagination-item {
			flex: 0 0 $smWidth !important;
			min-width: $smWidth !important;
			min-height: $smHeight + 2 !important;
		}
		li button {
			min-height: $smHeight !important;
			padding-block: 0px !important;
		}
	}

	.ant-pagination {
		& > *:first-child,
		& > *:last-child {
			min-width: fit-content !important;
		}
	}
}
