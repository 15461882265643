.header {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100px;
	// min-height: 80px;
	padding: 24px 0;
	box-shadow: 0px 0px 20px rgba(119, 123, 130, 0.26);

	.bg {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #123579 !important;
		width: 100%;
		z-index: -1;
		height: 100px;
		position: absolute;
		left: 0;
		top: 0;

		&::before,
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			height: 100%;
			background-repeat: no-repeat;
			background-position: top left;
		}

		&::before {
			left: 0;
			width: 281px;
			background-image: url(../../../../assets/images/svg/common/figure_svg_left.svg);
		}

		&::after {
			right: 0;
			width: 281px;
			background-image: url(../../../../assets/images/svg/common/figure_svg_right.svg);
		}
	}
}

.header_top {
	display: flex;
	justify-content: space-between;

	.left {
		display: flex;
		align-items: center;
		gap: 20px;
		color: #fff;
		font-size: 17px;
		font-weight: 700;
		display: flex;
		align-items: center;
		gap: 50px;

		span {
			display: flex;
			align-items: center;
			gap: 20px;
		}

		h2 {
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
		}

		// svg {
		// 	path {
		// 		fill: #fff;
		// 	}
		// }
	}

	.right {
		display: flex;
		align-items: center;
		gap: 20px;
		justify-content: flex-end;

		.user {
			display: flex;
			position: relative;
			justify-content: flex-start;
			align-items: center;
			padding-left: 10px;
			gap: 20px;

			img {
				height: 40px;
			}

			div {
				display: flex;
				flex-direction: column;
				// justify-content: center;
				line-height: 12px;
				text-align: left;
				align-items: flex-start;
				gap: 10px;
				h1 {
					font-size: 18px;
					font-style: normal;
					font-weight: 700;
					color: #f3f6f9;
					margin: 0;
				}
				p {
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					color: #f3f6f9;
				}
			}
		}
	}
}
