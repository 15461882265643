// Percent
@function percent($px, $from) {
	$result: math.div($px, $from) * 100%;
	@return $result;
}

// REM
@function rem($px) {
	$result: math.div($px, 16) + rem;
	@return $result;
}

// EM
/*   em(margin, font-size)
font-size: rem(24);
margin-right: em(10,24);
*/
@function em($px, $current: 16) {
	$result: math.div($px, $current) + em;
	@return $result;
}

$spaceAmounts: (
	-5,
	0,
	3,
	4,
	5,
	6,
	7,
	8,
	10,
	14,
	15,
	20,
	25,
	24,
	30,
	35,
	40,
	45,
	50,
	60,
	65,
	70,
	80,
	90,
	100,
	130,
	150,
	160,
	170,
	200,
	205,
	-10,
	-15,
	-20,
	-30
); // pixel amounts.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceAmounts {
	@each $side in $sides {
		.m#{str-slice($side, 0, 1)}-#{$space} {
			margin-#{$side}: #{$space}px !important;
		}
		.p#{str-slice($side, 0, 1)}-#{$space} {
			padding-#{$side}: #{$space}px !important;
		}
		.gap-#{$space} {
			gap: #{$space}px;
		}
		.fz-#{$space} {
			font-size: #{$space}px;
		}
		.size#{$space} {
			font-size: #{$space}px !important;
		}
		// MARGIN
		.m#{$space} {
			margin: #{$space}px;
		}
		.mx#{$space} {
			margin-inline: #{$space}px;
		}
		.my#{$space} {
			margin-block: #{$space}px;
		}
		.mb#{$space} {
			margin-bottom: #{$space}px;
		}
		.mt#{$space} {
			margin-top: #{$space}px;
		}
		.ml#{$space} {
			margin-left: #{$space}px;
		}
		.mr#{$space} {
			margin-right: #{$space}px;
		}
		// PADDING
		.p#{$space} {
			padding: #{$space}px;
		}
		.px#{$space} {
			padding-inline: #{$space}px;
		}
		.py#{$space} {
			padding-block: #{$space}px;
		}
		.pb#{$space} {
			padding-bottom: #{$space}px;
		}
		.pt#{$space} {
			padding-top: #{$space}px;
		}
		.pl#{$space} {
			padding-left: #{$space}px;
		}
		.pr#{$space} {
			padding-right: #{$space}px;
		}
	}
}
