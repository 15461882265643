a,
button {
	cursor: pointer;
}
body {
	background-color: #f8faff;
}

.container {
	max-width: 2500px;
	width: 2500px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 2100px) {
	.container {
		width: 100%;
		max-width: 2000px;
	}
}

@media (max-width: 2500px) {
	.container {
		width: 100%;
		max-width: 2400px;
	}
}
@media (max-width: 2400px) {
	.container {
		width: 100%;
		max-width: 2300px;
	}
}

@media (max-width: 2300px) {
	.container {
		width: 100%;
		max-width: 2200px;
	}
}

@media (max-width: 2200px) {
	.container {
		width: 100%;
		max-width: 2100px;
	}
}

@media (max-width: 2100px) {
	.container {
		width: 100%;
		max-width: 2000px;
	}
}

@media (max-width: 2050px) {
	.container {
		width: 100%;
		max-width: 1900px;
	}
}
@media (max-width: 1950px) {
	.container {
		width: 100%;
		max-width: 1800px;
	}
}

@media (max-width: 1850px) {
	.container {
		width: 100%;
		// max-width: 1700px;
	}
}

@media (max-width: 1750px) {
	.container {
		width: 100%;
		// max-width: 1600px;
	}
}

@media (max-width: 1640px) {
	.container {
		width: 100%;
		// max-width: 1540px;
	}
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 1540px) {
	.container {
		width: 100%;
		max-width: 1440px;
		padding: 10px;
	}
}

@media (max-width: 1440px) {
	.container {
		width: 100%;
		max-width: 1350px;
		padding: 10px;
	}
}
@media (max-width: 1340px) {
	.container {
		width: 100%;
		max-width: 1200px;
		padding: 10px;
	}
}
@media (max-width: 1240px) {
	.container {
		width: 100%;
		max-width: 1100px;
		padding: 10px;
	}
}
@media (max-width: 1140px) {
	.container {
		width: 100%;
		max-width: 1000px;
		padding: 10px;
	}
}
@media (max-width: 1040px) {
	.container {
		width: 100%;
		max-width: 960px;
		padding: 10px;
	}
}

@media (max-width: 940px) {
	.container {
		width: 100%;
		max-width: 800px;
		padding: 10px;
	}
}

.view {
	color: #123579;
	text-decoration: underline;
	cursor: pointer;
	transition: 0.5s;
	display: flex;

	&:hover {
		font-weight: bold;
	}
}

.table_scroll_top {
	.ant-table-body {
		transform: rotateX(180deg);

		& > table {
			transform: rotateX(180deg);
		}
	}
}

.text-center {
	text-align: center !important;
}

.italic {
	font-style: italic;
}

.capitalize {
	text-transform: capitalize !important;
}

.link {
	text-decoration: underline;
}

.bg_light {
	background: #f6f6f7;
}

.dark {
	color: #282f38;
}
.green {
	color: #8cc152;
}
.orange {
	color: #eeac00;
}
.brown {
	color: #c19e7a;
}
.blue,
.color {
	color: #123579;
}
.light {
	color: #777b82;
}
.light_blue {
	color: #b2c3ee;
}
.black {
	color: #000;
}
.white {
	color: #fff;
}

.bg_white {
	background: #fff;
}
.bg_light {
	background: #f6f6f7;
}
.bg_danger {
	background: #e9573f;
}
.bg_gray {
	background: #777b82;
}
.bg_success,
.bg_green {
	background: #8cc152;
}
.bg_success_light {
	background: #e5f9cf;
}

.bg_warning {
	background: #eeac00;
}
.bg_warning_light {
	background: #fdf9eb;
}

.bg_primary {
	background: #123579;
}
.bg_primary_light {
	background: #e8f0fd;
}
.bg_light_blue {
	background: #e8f0fd;
}

.dark-blue {
	color: #001f60;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-10 {
	margin-bottom: 10px;
}

.bold {
	font-weight: 700;
}

.modal {
	padding: 30px 20px;

	h3 {
		text-align: center;
		font-size: 22px;
		font-weight: 700;
		margin-bottom: 20px;
	}
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
