@font-face {
	font-family: "PT Sans";
	src: url("~assets/fonts/PTSans-Bold.woff2") format("woff2");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "PT Sans";
	src: url("~assets/fonts/PTSans-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
