*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.\!relative {
  position: relative !important;
}

.relative {
  position: relative;
}

.right-\[15\%\] {
  right: 15%;
}

.right-\[27\%\] {
  right: 27%;
}

.right-\[3\%\] {
  right: 3%;
}

.right-\[39\%\] {
  right: 39%;
}

.right-\[40\%\] {
  right: 40%;
}

.right-\[50\%\] {
  right: 50%;
}

.right-\[63\%\] {
  right: 63%;
}

.right-\[75\%\] {
  right: 75%;
}

.top-\[11\%\] {
  top: 11%;
}

.top-\[35\%\] {
  top: 35%;
}

.top-\[54\%\] {
  top: 54%;
}

.top-\[56\%\] {
  top: 56%;
}

.top-\[65\%\] {
  top: 65%;
}

.top-\[87\%\] {
  top: 87%;
}

.isolate {
  isolation: isolate;
}

.z-\[500000\] {
  z-index: 500000;
}

.\!col-span-2 {
  grid-column: span 2 / span 2 !important;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.\!mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.\!my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.\!mb-3 {
  margin-bottom: 0.75rem !important;
}

.\!ml-3 {
  margin-left: 0.75rem !important;
}

.\!ml-5 {
  margin-left: 1.25rem !important;
}

.\!mr-1 {
  margin-right: 0.25rem !important;
}

.\!mr-3 {
  margin-right: 0.75rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.\!h-10 {
  height: 2.5rem !important;
}

.\!h-16 {
  height: 4rem !important;
}

.\!h-20 {
  height: 5rem !important;
}

.\!h-4 {
  height: 1rem !important;
}

.\!h-5 {
  height: 1.25rem !important;
}

.\!h-8 {
  height: 2rem !important;
}

.\!h-9 {
  height: 2.25rem !important;
}

.\!h-\[20px\] {
  height: 20px !important;
}

.\!h-\[300px\] {
  height: 300px !important;
}

.\!h-\[450px\] {
  height: 450px !important;
}

.\!h-\[470px\] {
  height: 470px !important;
}

.\!h-\[500px\] {
  height: 500px !important;
}

.\!h-auto {
  height: auto !important;
}

.\!h-full {
  height: 100% !important;
}

.\!h-screen {
  height: 100vh !important;
}

.h-0 {
  height: 0px;
}

.h-16 {
  height: 4rem;
}

.h-5 {
  height: 1.25rem;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[40px\] {
  height: 40px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.\!max-h-\[350px\] {
  max-height: 350px !important;
}

.\!w-10 {
  width: 2.5rem !important;
}

.\!w-16 {
  width: 4rem !important;
}

.\!w-2\/3 {
  width: 66.666667% !important;
}

.\!w-64 {
  width: 16rem !important;
}

.\!w-80 {
  width: 20rem !important;
}

.\!w-9 {
  width: 2.25rem !important;
}

.\!w-full {
  width: 100% !important;
}

.\!w-screen {
  width: 100vw !important;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/5 {
  width: 20%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-96 {
  width: 24rem;
}

.w-\[22\%\] {
  width: 22%;
}

.w-\[70px\] {
  width: 70px;
}

.w-full {
  width: 100%;
}

.\!max-w-\[1500px\] {
  max-width: 1500px !important;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.\!flex-col {
  flex-direction: column !important;
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.\!items-center {
  align-items: center !important;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.\!gap-1 {
  gap: 0.25rem !important;
}

.\!gap-10 {
  gap: 2.5rem !important;
}

.\!gap-2 {
  gap: 0.5rem !important;
}

.\!gap-3 {
  gap: 0.75rem !important;
}

.\!gap-4 {
  gap: 1rem !important;
}

.\!gap-5 {
  gap: 1.25rem !important;
}

.\!gap-7 {
  gap: 1.75rem !important;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-5 {
  gap: 1.25rem;
}

.\!gap-y-2 {
  row-gap: 0.5rem !important;
}

.\!gap-y-5 {
  row-gap: 1.25rem !important;
}

.\!space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}

.\!space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
}

.\!space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.\!border {
  border-width: 1px !important;
}

.\!border-\[0\.5px\] {
  border-width: 0.5px !important;
}

.border {
  border-width: 1px;
}

.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-dotted {
  border-style: dotted;
}

.\!border-\[\#1E61B4\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 97 180 / var(--tw-border-opacity)) !important;
}

.\!border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.\!border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.\!bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.\!bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#4CAF50\] {
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
}

.bg-\[\#F5F9FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-100\/10 {
  background-color: rgb(243 244 246 / 0.1);
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-primary-def {
  --tw-bg-opacity: 1;
  background-color: rgb(18 53 121 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.\!p-0 {
  padding: 0px !important;
}

.\!p-1 {
  padding: 0.25rem !important;
}

.\!p-10 {
  padding: 2.5rem !important;
}

.\!p-5 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.25rem;
}

.\!px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.\!px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.\!px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.\!px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.\!px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.\!py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.\!py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.\!py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.\!py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.\!py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.\!py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.\!pb-2 {
  padding-bottom: 0.5rem !important;
}

.\!pl-1 {
  padding-left: 0.25rem !important;
}

.\!pl-10 {
  padding-left: 2.5rem !important;
}

.\!pl-4 {
  padding-left: 1rem !important;
}

.\!pt-1 {
  padding-top: 0.25rem !important;
}

.\!pt-3 {
  padding-top: 0.75rem !important;
}

.pl-20 {
  padding-left: 5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.text-center {
  text-align: center;
}

.\!text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.\!text-\[14px\] {
  font-size: 14px !important;
}

.\!text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.\!text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.\!text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.\!font-normal {
  font-weight: 400 !important;
}

.\!font-thin {
  font-weight: 100 !important;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.\!capitalize {
  text-transform: capitalize !important;
}

.capitalize {
  text-transform: capitalize;
}

.\!text-\[\#102E7A\] {
  --tw-text-opacity: 1 !important;
  color: rgb(16 46 122 / var(--tw-text-opacity)) !important;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.\!text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-\[\#DD1616\] {
  --tw-text-opacity: 1;
  color: rgb(221 22 22 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-primary-def {
  --tw-text-opacity: 1;
  color: rgb(18 53 121 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\!no-underline {
  -webkit-text-decoration-line: none !important;
          text-decoration-line: none !important;
}

.opacity-50 {
  opacity: 0.5;
}

.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

::before,
::after {
  --tw-content: '';
}

.ant-upload {
  width: 100% !important;
}

.border {
  border: 1px solid
}

.spinner_OSmW{
  -webkit-transform-origin:center;
          transform-origin:center;
  -webkit-animation:spinner_T6mA .75s step-end infinite;
          animation:spinner_T6mA .75s step-end infinite
}

@-webkit-keyframes spinner_T6mA{
  8.3%{
    -webkit-transform:rotate(30deg);
            transform:rotate(30deg)
  }

  16.6%{
    -webkit-transform:rotate(60deg);
            transform:rotate(60deg)
  }

  25%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg)
  }

  33.3%{
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg)
  }

  41.6%{
    -webkit-transform:rotate(150deg);
            transform:rotate(150deg)
  }

  50%{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg)
  }

  58.3%{
    -webkit-transform:rotate(210deg);
            transform:rotate(210deg)
  }

  66.6%{
    -webkit-transform:rotate(240deg);
            transform:rotate(240deg)
  }

  75%{
    -webkit-transform:rotate(270deg);
            transform:rotate(270deg)
  }

  83.3%{
    -webkit-transform:rotate(300deg);
            transform:rotate(300deg)
  }

  91.6%{
    -webkit-transform:rotate(330deg);
            transform:rotate(330deg)
  }

  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

@keyframes spinner_T6mA{
  8.3%{
    -webkit-transform:rotate(30deg);
            transform:rotate(30deg)
  }

  16.6%{
    -webkit-transform:rotate(60deg);
            transform:rotate(60deg)
  }

  25%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg)
  }

  33.3%{
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg)
  }

  41.6%{
    -webkit-transform:rotate(150deg);
            transform:rotate(150deg)
  }

  50%{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg)
  }

  58.3%{
    -webkit-transform:rotate(210deg);
            transform:rotate(210deg)
  }

  66.6%{
    -webkit-transform:rotate(240deg);
            transform:rotate(240deg)
  }

  75%{
    -webkit-transform:rotate(270deg);
            transform:rotate(270deg)
  }

  83.3%{
    -webkit-transform:rotate(300deg);
            transform:rotate(300deg)
  }

  91.6%{
    -webkit-transform:rotate(330deg);
            transform:rotate(330deg)
  }

  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

.placeholder\:text-sm::-webkit-input-placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.placeholder\:text-sm::placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.focus-within\:\!border-gray-400:focus-within {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.hover\:\!bg-gray-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-100\/20:hover {
  background-color: rgb(243 244 246 / 0.2);
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: 0.9;
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:\!bg-gray-300:active {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
}

.active\:bg-opacity-100:active {
  --tw-bg-opacity: 1;
}