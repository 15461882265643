.wrap{
  position: relative;
  width: 100%;

  label{
    position: absolute;
    top: 0;
    left: 10px;
    transform: translateY(-50%);
    z-index: 111;
    padding: 0 3px;
    background: #fff;
    line-height: 1.1;
  }
}