@import "~assets/styles/abstracts/variables.scss";

* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	box-sizing: border-box;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

html,
body {
	height: 100%;
	font-family: $font-family !important;
	font-size: $font-size;
}

#root {
	min-height: 100%;
	display: grid;
	grid-template-rows: auto 1fr auto;
}
.ant-table-filter-column {
	align-items: center;
}

.ant-table-filter-trigger {
	transform: scale(1.2);
	background: rgba(#123579, 0.1);
	height: 28px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: rgba(#123579, 0.2);
	}

	& > span {
		color: #123579 !important;
	}
}

.remove-table-column {
	.render,
	span {
		visibility: hidden !important;
		display: none;
	}
}

.table_scroll_top {
	.ant-table-body {
		transform: rotateX(180deg) !important;

		& > table {
			transform: rotateX(180deg) !important;
		}
	}
}

table {
	thead {
		.custom_head_one,
		.custom_head_two,
		.custom_head_three {
			background: #eaeffa !important;
			border-color: #c9d9fa !important;
		}
	}
}

.login-box {
	height: 100%;
	cursor: pointer;
	background: #fff;
	box-shadow: 0px 4px 15px rgba(169, 169, 169, 0.18);
	border-radius: 17px;
	padding: 27px 44px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	svg {
		flex: 1 1 auto;
	}

	&.active-login-box,
	&:not(.login-box-disabled):hover {
		background: $primary;
		color: #fff;
		svg[name="e-imzo"],
		svg[name="ESP"] {
			* {
				fill: #fff;
			}
		}
	}
}

.login-box-disabled {
	cursor: default !important;
	background: #faf8f8 !important;
	box-shadow: none !important;
}

.status_active {
	color: #28a745;
	text-decoration: underline;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
}

.status_noactive {
	color: #ff0000;
	text-decoration: underline;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
}

.action {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	button {
		box-shadow: 0px 10px 10px 0px rgba(67, 68, 169, 0.25);
	}

	svg {
		&:hover {
			cursor: pointer;
		}
	}
}

.btn-download {
	display: flex;
	align-items: center;
	gap: 10px;
	background-color: $primary;
	color: #fff;
	padding: 3px 16px;
	border-radius: 60px;
}
