@import "../../../../assets/styles/abstracts/variables";
.sidebar {
	// padding-top: 38px;
	width: 100%;
	// min-width: 300px;
	height: 100%;
	// border-radius: 15px;
	background: #123579;

	ul {
		list-style: none;
		& > li {
			min-height: 35px;
			// margin-bottom: 20px;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			color: $dark;
			transition: all 0.5s;

			a {
				display: flex;
				align-items: center;
				gap: 20px;
				padding: 6px 20px;
				color: #fff;
				text-decoration: none;
			}
		}

		& > .active {
			background: rgba(3, 38, 111, 0.5);
			position: relative;

			a {
				&::after {
					content: "";
					display: block;
					width: 4px;
					height: 100%;
					background-color: #fff;
					position: absolute;
					left: 0;
					border-radius: 0px 2px 2px 0px;
				}
			}
		}
	}
}
