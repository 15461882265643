@use "assets/styles/abstracts/mixins/flex" as flex;

.table {
	--active-color: #123579;
	--font-color: #282f38;
	--rows-cols-border-color: #f6f6f7;
	--rows-cols-border-color2: #e6e9ed;
	--row-bg-color: #d7d8da;
	--disabled-color: #777b82;

	position: relative;

	// header section
	:global {
		thead {
			// background-color: var(--active-color) !important;

			th.ant-table-cell {
				color: #fff;
				// background: var(--rows-cols-border-color) !important;
				&::before {
					transform: none !important;
					height: 100% !important;
					top: 0 !important;
					// background: var(--row-bg-color) !important;
				}

				&:first-child {
					// border-start-start-radius: 15px !important;
				}
				&:last-child {
					// border-start-end-radius: 15px !important;
				}
			}
			> tr > th {
				background-color: var(--active-color) !important;
			}
		}
	}
	// body section
	:global {
		tbody {
			td.ant-table-cell {
				border-right: 1px solid var(--rows-cols-border-color2);
				border-color: var(--rows-cols-border-color2) !important;
				&:first-child {
					border-left: 1px solid var(--rows-cols-border-color2);
				}
			}
			.ant-table-cell-row-hover {
				border-radius: 0 !important;
			}

			tr:nth-child(even) {
				background: var(--rows-cols-border-color) !important;
			}
		}
	}
}
div {
	::-webkit-scrollbar {
		width: 5px;
		height: 5px !important;
	}

	::-webkit-scrollbar-track {
		background: #e6e9ed;
	}

	::-webkit-scrollbar-thumb {
		background: #5b7ed7 !important;
		border-radius: 30px;
	}
}
