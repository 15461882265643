@use "assets/styles/abstracts/mixins/_flex" as flex;

/*******************************
            Flex Layout
  *******************************/
.d-flex {
	@include flex.d-flex;
}

.flex-justify-center {
	@include flex.d-flex;
	justify-content: center;
}

.flex-justify-around {
	@include flex.d-flex;
	justify-content: space-around;
}

.flex-justify-between {
	@include flex.d-flex;
	justify-content: space-between;
}

.flex-justify-end {
	@include flex.d-flex;
	justify-content: flex-end;
}

.flex-align-center {
	@include flex.d-flex;
	align-items: center;
}

.flex-both-center {
	@include flex.d-flex;
	align-items: center;
	justify-content: center;
}

.flex-justify-align {
	@include flex.d-flex;
	align-items: center;
}

.flex-between-center {
	@include flex.d-flex;
	align-items: center;
	justify-content: space-between;
}

.flex-direction-column {
	@include flex.d-flex;
	flex-direction: column;
}

.flex-direction-both-center {
	@include flex.flex-direction-column;
	@include flex.flex-both-center;
}
