.main {
	padding: 20px 0;
	border-radius: 25px;
	background: #fff;
	z-index: 1;
	position: relative;
	top: -60px;
	min-height: 70vh;
	display: flex;
	width: 100%;

	.content {
		display: flex;
		gap: 20px;
		width: 100%;
		border-radius: 20px;

		main {
			width: 80% !important;
		}
	}
}
