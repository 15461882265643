.create {
	.add {
		color: #fff;
		padding: 5px 15px;
		border-radius: 5px;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
}
.modal {
	.add_title {
		text-align: center !important;
        font-size: 20px;
        font-weight: 700;
        margin: 20px auto;
	}
}
