.footer {
	border-top: 1px solid #afabab;
	padding-block: 25px;

	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		p {
			margin: 0;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
		}

		h3 {
			display: flex;
			align-items: center;
			gap: 10px;

			p {
				margin: 0;
				font-size: 40px;
				font-style: normal;
				font-weight: 700;
				color: #123579;
			}
		}
	}
}
