.dropdown {
	cursor: pointer;
	color: #fff;

	span {
		color: #f3f6f9;
		font-family: PT Sans;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px; /* 140% */
		text-decoration-line: underline;
	}
}
